import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, useParams  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody,TextField, Button, Select, TablePagination, MenuItem, InputLabel, FormControl, Chip } from '@mui/material';

import { renderDateTimeLong, capitalizeFirstLetter, renderKr } from 'utils/formatter';
import { getAccessOrders } from 'store/slices/orders';
import paths from 'paths';

import { selectAccessOrders } from 'store';
import { statusOptions, legalProducts, financialProducts } from 'utils/constants';
import { config } from 'utils/config';

const Orders = () => {
    const dispatch = useDispatch();
    const { organisationId } = useParams();
    const organisation = useSelector(state => state.auth.user.organisations.find(org => org.organisationId === organisationId));
    const loaded = useSelector(state => state.accessOrders.loaded);
    const loading = useSelector(state => state.accessOrders.loading);
    const count = useSelector(state => state.accessOrders.count);
    const orders = useSelector(selectAccessOrders);

    const [search, setSearch] = useState('');
    // Pagination state
    const [page, setPage] = useState();
    const [pageSize, setPageSize] = useState();
    const [sortBy, setSortBy] = useState();
    const [sortOrder, setSortOrder] = useState();
    const [filters, setFilters] = useState([]);
    const searchRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    const showLegal = organisation.organisationId === 'alliance-advokater';
    const showAll = config.env === 'test';
    // const advisorsToShow = organisation.organisationId === 'afpr' ?
    //     ['Kourosh Rasmussen', 'Jørgen Leschly Thorsted','Trine Sund Kammersgaard', 'John Rosenbaum']: config.env === 'test' ?
    //         ['Asbjørn Dyhrberg Thegler']:
    //         [];

    // eslint-disable-next-line no-nested-ternary
    const productOptions = showLegal ? legalProducts : showAll ? [...financialProducts, ...legalProducts] : financialProducts;

    useEffect(() => {
        fetchOrders();
    }, [page, pageSize, search, sortBy, sortOrder, filters]);

    function fetchOrders() {
        const params = new URLSearchParams(location.search);

        setParam(params, 'page', page);
        setParam(params, 'pageSize', pageSize);
        setParam(params, 'search', search);
        setParam(params, 'sortBy', sortBy);
        setParam(params, 'sortOrder', sortOrder);
        if (filters.length > 0) {
            setParam(params, 'filter', JSON.stringify(filters));
        }
        dispatch(getAccessOrders(organisationId, params));
        navigate(`?${params.toString()}`, { replace: true });
    }

    const setParam = (params, key, value) => {
        if(value){
            params.set(key, value);
        }
        if(value === '' || value === 0){
            params.delete(key);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };
    const handleChangeSorting = (newSortBy) => {
        console.log(newSortBy);
        setSortBy(newSortBy);
        const flippedSortOrder = sortOrder === 'asc'  ? 'desc' : 'asc';
        // eslint-disable-next-line no-nested-ternary
        const newSortOrder = sortBy === newSortBy ? flippedSortOrder : newSortBy === 'orderNumber' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
    };
    const handleChangePageSize = (event) => {
        const pageSize = parseInt(event.target.value, 10);
        console.log('pages', pageSize);
        setPageSize(pageSize);
        setPage(0);
    };
    const onRefresh = () => fetchOrders();

    const getOrderPath = (orderId) => paths.penlyAccessOrder.getPath(organisationId, orderId);

    const updateFilter = (property, operation, value) => {
        const updatedFilters = [...filters];
        const existingFilterIndex = updatedFilters.findIndex(filter => filter.property === property);

        if (existingFilterIndex !== -1) {
            // Update existing filter
            updatedFilters[existingFilterIndex] = { property, operation, value };
        } else {
            // Add new filter
            updatedFilters.push({ property, operation, value });
        }
        // Update component state
        setFilters(updatedFilters);
    };
    const handleClearFilters = () => {
        setSearch('');
        setPage(0);
        setSortBy(undefined);
        setSortOrder(undefined);
        setFilters([]);

        const params = new URLSearchParams(location.search);
        params.delete('search');
        params.delete('filter');
        params.delete('page');
        params.delete('sortBy');
        params.delete('sortOrder');
        searchRef.current.value = '';
        navigate(`?${params.toString()}`, { replace: true });
    };
    function debounceSearch(func, timeout = 300){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
    function triggerSearch(value){
        setSearch(value);
    }
    const handleSearchChange = debounceSearch((e) => triggerSearch(e.target.value));
    return <>
        { !loaded && (
            <div className="App-section-loading">
                <CircularProgress  color="primary" size={ 40 } />
            </div>
        ) }
        { loaded && (
            <>

                <div style={{ display: 'flex', justifyContent:'space-between', minWidth:1300 }}>
                    <TextField
                        label="Søg"
                        onChange={ handleSearchChange }
                        inputRef={ searchRef }
                    />
                    <Button
                        disabled={ loading }
                        endIcon={ <RefreshIcon /> }
                        onClick={ onRefresh }
                    >
                        { loading ? 'Opdaterer' : 'Opdatér' }
                    </Button>
                </div>

                <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                    { /* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="advisor-label">Rådgiver</InputLabel>
                        <Select
                            labelId="advisor-label"
                            value={ advisorFilter }
                            onChange={ (e) => setAdvisorFilter(e.target.value) }
                            label="Advisor"
                        >
                            { advisorOptions.map((advisor) => (
                                <MenuItem key={ advisor.value } value={ advisor.value }>
                                    { advisor.name }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl> */ }
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="product-label">Produkt</InputLabel>
                        <Select
                            labelId="product-label"
                            value={ filters.find(x => x.property === 'items.product.id')?.value ?? '' }
                            onChange={ (e) => updateFilter('items.product.id', 'equals', e.target.value) }
                            label="Product"
                        >
                            { productOptions.map((product) => (
                                <MenuItem key={ product.value } value={ product.value }>
                                    { product.name }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select
                            labelId="status-label"
                            value={ filters.find(x => x.property === 'status')?.value ?? '' }
                            onChange={ (e) => updateFilter('status', 'equals', e.target.value) }
                            label="Status"
                        >
                            { statusOptions.map((status) => (
                                <MenuItem key={ status.value } value={ status.value }>
                                    { status.name }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>
                    { /* { advisorsToShow.length > 0 &&
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="advisor-label">Rådgiver</InputLabel>
                        <Select
                            labelId="advisor-label"
                            value={ filters.find(x => x.property === 'advisor')?.value ?? '' }
                            onChange={ (e) => updateFilter('advisor', 'equals', e.target.value) }
                            label="Rådgiver"
                        >
                            { advisorsToShow.map((x) => (
                                <MenuItem key={ x } value={ x }>
                                    { x }
                                </MenuItem>
                            )) }
                        </Select>
                    </FormControl>
                    } */ }
                    <Button style={{ marginTop:20 }} onClick={ handleClearFilters }>Ryd filtre</Button>

                </div>
                <TableContainer sx={{ mt: 0 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={ sortBy === 'orderNumber' }
                                        direction={ sortBy === 'orderNumber' ? sortOrder : 'asc' }
                                        onClick={ () => handleChangeSorting('orderNumber') }
                                    >
                                        Ordre #
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={ sortBy === 'customer.email' }
                                        direction={ sortBy === 'customer.email' ? sortOrder : 'asc' }
                                        onClick={ () => handleChangeSorting('customer.email') }
                                    >
                                        Kunde
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={ sortBy === 'createdAt' }
                                        direction={ sortBy === 'createdAt' ? sortOrder : 'asc' }
                                        onClick={ () => handleChangeSorting('createdAt') }
                                    >
                                        Bestillingsdato
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left">          <TableSortLabel
                                    active={ sortBy === 'bookings.startTime' }
                                    direction={ sortBy === 'bookings.startTime' ? sortOrder : 'asc' }
                                    onClick={ () => handleChangeSorting('bookings.startTime') }
                                >
                                    Mødedato
                                </TableSortLabel>
                                </TableCell>
                                <TableCell align="left">
                                    Rådgiver
                                </TableCell>
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={ sortBy === 'sharingIds' }
                                        direction={ sortBy === 'sharingIds' ? sortOrder : 'asc' }
                                        onClick={ () => handleChangeSorting('sharingIds') }
                                    >
                                        Deling
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left">
                                    Mødeklar
                                </TableCell>
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={ sortBy === 'items.product.name' }
                                        direction={ sortBy === 'items.product.name' ? sortOrder : 'asc' }
                                        onClick={ () => handleChangeSorting('items.product.name') }
                                    >
                                        Produkter
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="left">
                                    Pris
                                </TableCell>
                                { /* <TableCell align="left">Rådgiver</TableCell> */ }
                                <TableCell align="left">
                                    <TableSortLabel
                                        active={ sortBy === 'status' }
                                        direction={ sortBy === 'status' ? sortOrder : 'asc' }
                                        onClick={ () => handleChangeSorting('status') }
                                    >
                                        Status
                                    </TableSortLabel></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { orders
                                .map((order) => (
                                    <TableRow key={ order.orderId } onClick={ () => navigate(getOrderPath(order.orderId)) } style={{ cursor: 'pointer' }}>

                                        <TableCell align="left" sx={{ fontWeight: 700 }}>

                                            { order.orderNumber }
                                        </TableCell>
                                        <TableCell align="left">{ order.customer.userId ? `${order.customer.firstName} ${order.customer.lastName}` : order.customer.email }</TableCell>
                                        <TableCell align="left">{ capitalizeFirstLetter(renderDateTimeLong(order.createdAt)) }</TableCell>
                                        <TableCell align="left">
                                            { order.bookings && !!order.bookings[0] ? capitalizeFirstLetter(renderDateTimeLong(order.bookings[0].startTime)) : '' }
                                        </TableCell>
                                        <TableCell align="left">
                                            { order.bookings && !!order.bookings[0] ? order.bookings[0].vendorsAttendees[0] : '' }
                                        </TableCell>
                                        <TableCell align="left">
                                            <Chip label=                                            { order.sharingIds && !!order.sharingIds[0] ? 'Ja' : 'Nej' }
                                            >

                                            </Chip>
                                        </TableCell>
                                        <TableCell align="left">
                                            { order.dataCollectionProgress && order.dataCollectionProgress.currentStep &&
                                            <>
                                                {  order.dataCollectionProgress.completed ?
                                                    <Chip color="primary"  label={  'Ja' }></Chip>:
                                                    <Chip  label={ 'Nej' }></Chip>
                                                }
                                            </>

                                            }
                                        </TableCell>
                                        <TableCell align="left">
                                            { order.items.map(item => item.product?.id === 'pensionanalysis-review-chatbot' ? `${item.product?.name  } (via chatbot)` : item.product?.name ).join(', ') }
                                        </TableCell>
                                        <TableCell align="left">
                                            { order.items.every(x => x.product?.type !== 'subscription') && renderKr(order.items.reduce((acc, item) => {
                                                const productPrice = item.product?.price.now || item.product?.price.standard || item.product?.price.standart || 0;
                                                const itemTotal = productPrice * item.quantity;
                                                return acc + itemTotal;
                                            }, 0)) }
                                            { order.items.some(x => x.product?.type === 'subscription') &&  `${renderKr(order.items.reduce((acc, item) => {
                                                const productPrice = item.product?.price.yearly?.now || item.product?.price.yearly?.standard || 0;
                                                const itemTotal = productPrice * item.quantity;
                                                return acc + itemTotal;
                                            }, 0))  } / år` }
                                        </TableCell>
                                        { /* <TableCell align="left">
                                            { order.assignedAdvisor?.name }
                                        </TableCell> */ }
                                        <TableCell align="left">
                                            <Chip label={ statusOptions.find(x => x.value === order.status)?.name || order.status }>

                                            </Chip>

                                        </TableCell>
                                    </TableRow>
                                )) }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={ count }
                    page={ page || 0 }
                    onPageChange={ handleChangePage }
                    rowsPerPage={ pageSize || 50 }
                    onRowsPerPageChange={ handleChangePageSize }
                    labelRowsPerPage="Rækker pr. side"
                    labelDisplayedRows={ ({ from, to, count }) => `${from}-${to} af ${count}` }
                />
            </>
        ) }
    </>;
};

export default Orders;
